import { createTypedReducerWithImmer } from "@pp/Common/CreateTypedReducer";
import { ContextState } from "@pp/Store/Context/ContextState";
import { Draft } from "immer";

const initialState: ContextState = {
    selectedBoardId: "",
};

const reducer = {
    "Context/SetSelectedBoardId": (draft: Draft<ContextState>, action: { value: string }) => {
        draft.selectedBoardId = action.value;
    },
};

export const contextReducer = createTypedReducerWithImmer(initialState, reducer);
