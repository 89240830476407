import { Dictionary } from "@pp/Common/Types";
import { colors } from "@pp/Components/Common/Colors";
import { SprintPlannerColumnHeaderPoints } from "@pp/Components/SprintPlanner/SprinterPlannerColumnHeaderPoints";
import { SprintPlannerIssue } from "@pp/Components/SprintPlanner/SprintPlannerIssue";
import { ApplicationState } from "@pp/Store/ApplicationState";
import { setHoveringOverAssigneeId } from "@pp/Store/Components/SprintPlanner/SprintPlannerActionCreators";
import { loadAccount } from "@pp/Store/Data/ActionCreators/LoadAccount";
import { getById } from "@pp/Store/Data/DataSelectors";
import { IssueModel } from "@pp/Store/Types";
import { connect, ResolveThunks } from "react-redux";
import * as React from "react";
import styled from "styled-components";

interface OwnProps {
    assigneeId: string;
    issues: IssueModel[];
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => ({
    assignee:
        ownProps.assigneeId === ""
            ? {
                  value: {
                      displayName: "Unassigned",
                      id: "",
                      emailAddress: "",
                      avatarUrl: "/images/transparent.gif",
                  },
              }
            : getById(state.data.accounts, ownProps.assigneeId),
    showDropIssue: state.components.sprintPlanner.hoveringOverAssigneeId === ownProps.assigneeId,
});

const mapDispatchToProps = {
    setHoveringOverAssigneeId,
    loadAccount,
};

type Props = ResolveThunks<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & OwnProps;

class Component extends React.Component<Props> {
    dragOver = () => {
        this.props.setHoveringOverAssigneeId(this.props.assigneeId);
    };

    render() {
        if (!this.props.assignee.value) {
            if (!this.props.assignee.isLoading) {
                this.props.loadAccount(this.props.assigneeId);
            }
            return null;
        }

        const pointsByStatusId: Dictionary<number> = {};
        for (const issue of this.props.issues) {
            if (!pointsByStatusId[issue.statusId]) {
                pointsByStatusId[issue.statusId] = 0;
            }

            pointsByStatusId[issue.statusId]! += issue.points ?? 0;
        }

        return (
            <AssigneeColumnStyle onDragOver={this.dragOver}>
                <Wrapper>
                    <AssigneeHeaderStyle>
                        <AssigneeIcon src={this.props.assignee.value.avatarUrl} />
                        <NameStyle>{this.props.assignee.value.displayName}</NameStyle>
                        <SprintPlannerColumnHeaderPoints pointsByStatusId={pointsByStatusId} />
                    </AssigneeHeaderStyle>
                    <IssuesStyle>
                        {this.props.issues.map(issue => (
                            <SprintPlannerIssue issue={issue} key={issue.id} />
                        ))}
                        {this.props.showDropIssue && <DropIssueStyle />}
                    </IssuesStyle>
                </Wrapper>
            </AssigneeColumnStyle>
        );
    }
}

const AssigneeColumnStyle = styled.div`
    min-width: 240px;
    max-width: 240px;
    background-color: ${colors.lightBackground};
    border-radius: 4px;
    display: table-cell;
`;

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const AssigneeHeaderStyle = styled.div`
    background-color: ${colors.lightBackground};
    position: sticky;
    display: flex;
    text-align: center;
    padding: 0 10px;
    align-items: center;
    height: 40px;
    min-height: 40px;
    top: 0;
    z-index: 1;
`;

const AssigneeIcon = styled.img`
    width: 30px;
    height: 30px;
`;

const NameStyle = styled.div`
    text-align: center;
    padding: 0 5px;
    flex-grow: 1;
`;

const IssuesStyle = styled.div`
    flex: 1;
`;

const DropIssueStyle = styled.div`
    background-color: #888;
    height: 80px;
    margin: 4px;
    border-radius: 2px;
    box-shadow: 0px 1px 2px 0px rgba(9, 30, 66, 0.25);
`;

export const SprintPlannerColumn = connect(mapStateToProps, mapDispatchToProps)(Component);
