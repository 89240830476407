import { Dictionary } from "@pp/Common/Types";
import { colors } from "@pp/Components/Common/Colors";
import { ApplicationState } from "@pp/Store/ApplicationState";
import { getById } from "@pp/Store/Data/DataSelectors";
import { StatusModel } from "@pp/Store/Types";
import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

interface OwnProps {
    pointsByStatusId: Dictionary<number>;
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    const statusByStatusId: Dictionary<StatusModel> = {};
    for (const statusId in ownProps.pointsByStatusId) {
        const status = getById(state.data.statuses, statusId).value;
        statusByStatusId[statusId] = status;
    }

    return {
        statusByStatusId,
    };
};

type Props = OwnProps & ReturnType<typeof mapStateToProps>;

class Component extends React.Component<Props> {
    render() {
        let totalPoints = 0;
        let items = [];
        for (const statusId in this.props.pointsByStatusId) {
            const points = this.props.pointsByStatusId[statusId];
            if (points) {
                items.push({
                    points,
                    status: this.props.statusByStatusId[statusId]?.name ?? "Unknown",
                });
                totalPoints += points;
            }
        }

        return (
            <PointsStyle>
                {totalPoints > 0 && (
                    <HoverPosition>
                        <HoverStyle>
                            {items.map(o => (
                                <div key={o.status}>
                                    <span>{o.status}</span>
                                    <span>{o.points}</span>
                                </div>
                            ))}
                        </HoverStyle>
                    </HoverPosition>
                )}
                {totalPoints}
            </PointsStyle>
        );
    }
}

const HoverPosition = styled.div`
    display: none;
    padding-left: 4px;
    position: absolute;
    z-index: 1000;
    right: 0;
    top: 100%;
    margin-top: 2px;
`;

const HoverStyle = styled.div`
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.2);
    padding: 5px 10px;
    div {
        white-space: nowrap;
        display: flex;
        span:first-child {
            margin-right: 10px;
        }
        span:last-child {
            margin-left: auto;
        }
    }
`;

const PointsStyle = styled.div`
    text-align: center;
    width: 30px;
    background-color: ${colors.pointsBackground};
    border-radius: 15px;
    color: ${colors.pointsText};
    padding: 5px;
    position: relative;
    &:hover ${HoverPosition} {
        display: block;
    }
    z-index: 1;
`;

export const SprintPlannerColumnHeaderPoints = connect(mapStateToProps)(Component);
