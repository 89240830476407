import { App } from "@pp/Components/App";
import * as React from "react";
import { render } from "react-dom";
import { configureStore } from "@pp/Store/ConfigureStore";
import { Provider } from "react-redux";
import { ApplicationState } from "@pp/Store/ApplicationState";

const store = configureStore({} as ApplicationState);

render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root"),
);
