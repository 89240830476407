import X from "@pp/Components/Icons/X";
import * as React from "react";
import styled from "styled-components";

interface Props {
    title: string;
    isOpen: boolean;
    close: () => void;
}

class Component extends React.Component<Props> {
    onClose = () => {
        this.props.close();
    };

    render() {
        if (!this.props.isOpen) {
            return null;
        }

        return (
            <Overlay>
                <ModalContainer>
                    <ModalHeader>
                        <HeaderContent>{this.props.title}</HeaderContent>
                        <CloseButton onClick={this.onClose}>
                            <X size={20} />
                        </CloseButton>
                    </ModalHeader>
                    <ModalContent>{this.props.children}</ModalContent>
                </ModalContainer>
            </Overlay>
        );
    }
}

export const Modal = Component;

const Overlay = styled.div`
    position: fixed;
    background-color: rgba(0, 0, 0, 0.3);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
`;

const ModalContainer = styled.div`
    position: absolute;
    background-color: white;
    border-radius: 3px;
    left: 50%;
    top: 50%;
    padding: 20px;
    width: 500px;
    transform: translate(-50%, -50%);
`;

const ModalHeader = styled.div`
    display: flex;
    align-items: center;
`;

const HeaderContent = styled.div`
    font-weight: bold;
    font-size: 16px;
    margin-right: auto;
`;

const CloseButton = styled.button`
    background: transparent;
    border: none;
    font-size: 20px;
    font-weight: bold;
    outline: none;
    cursor: pointer;
    display: flex;
`;

const ModalContent = styled.div``;
