import { createTypedReducerWithImmer } from "@pp/Common/CreateTypedReducer";
import { BoardSettingsState } from "@pp/Store/Components/BoardSettings/BoardSettingsState";
import { Draft } from "immer";

const initialState: BoardSettingsState = {
    isOpen: false,
};

const reducer = {
    "Components/BoardSettings/Open": (draft: Draft<BoardSettingsState>) => {
        draft.isOpen = true;
    },
    "Components/BoardSettings/Close": (draft: Draft<BoardSettingsState>) => {
        draft.isOpen = false;
    },
};

export const boardSettingsReducer = createTypedReducerWithImmer(initialState, reducer);
