import { applyMiddleware, combineReducers, compose, createStore, ReducersMapObject, StoreEnhancer, StoreEnhancerStoreCreator } from "redux";
import * as StoreModule from "@pp/Store/Reducers";
import { ApplicationState } from "@pp/Store/ApplicationState";
import reduxThunk from "redux-thunk";

const reducers = StoreModule.reducers;

export function configureStore(initialState: ApplicationState) {
    const windowIfDefined = typeof window === "undefined" ? null : (window as any);
    const devToolsExtension = windowIfDefined && (windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__ as () => StoreEnhancer);

    const createStoreWithMiddleware = compose<StoreEnhancerStoreCreator<ApplicationState>>(
        applyMiddleware(reduxThunk),
        devToolsExtension ? devToolsExtension() : <S>(next: StoreEnhancerStoreCreator<S>) => next,
    )(createStore);

    function buildRootReducer(value: ReducersMapObject) {
        return combineReducers({ ...value });
    }

    const allReducers = buildRootReducer(reducers as any);
    const store = createStoreWithMiddleware(allReducers, initialState);

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept("./Reducers", () => {
            const nextRootReducer = require("./Reducers");
            store.replaceReducer(buildRootReducer(nextRootReducer.reducers as any));
        });
    }

    return store;
}
