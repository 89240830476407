import { ApplicationState } from "@pp/Store/ApplicationState";
import { getById } from "@pp/Store/Data/DataSelectors";
import { PropertyModel } from "@pp/Store/Types";

export const enum BoardProperties {
    ppAssigneeField = "ppAssigneeField",
    ppTeamMembersField = "ppTeamMembersField",
}

export function getBoardAssigneeField(state: ApplicationState, boardId: string) {
    return getById<PropertyModel<string | undefined>>(state.data.properties, getBoardAssigneeFieldUrl(boardId));
}

export function getBoardAssigneeFieldUrl(boardId: string) {
    return getBoardPropertyUrl(boardId, BoardProperties.ppAssigneeField);
}

export function getBoardTeamMembersField(state: ApplicationState, boardId: string) {
    return getById<PropertyModel<string[] | undefined>>(state.data.properties, getBoardTeamMembersFieldUrl(boardId));
}

export function getBoardTeamMembersFieldUrl(boardId: string) {
    return getBoardPropertyUrl(boardId, BoardProperties.ppTeamMembersField);
}

export function getBoardPropertyUrl(boardId: string, propertyName: BoardProperties) {
    return `/rest/agile/1.0/board/${boardId}/properties/${propertyName}`;
}
