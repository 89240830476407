import { RequestParameter } from "@pp/Atlassian/Request";
import { createTypedReducerWithImmer } from "@pp/Common/CreateTypedReducer";
import { getDataViewKey } from "@pp/Store/Data/DataSelectors";
import { DataState, DataType } from "@pp/Store/Data/DataState";
import { DataView, DataViewState } from "@pp/Store/Data/DataView";
import { Draft } from "immer";
import assign from "lodash/assign";

const initialState: DataState = {};

const reducer = {
    "Data/BeginLoadingModel": (draft: Draft<DataState>, action: { id: string; dataType: DataType }) => {
        let dataViewState = draft[action.dataType];
        if (!dataViewState) {
            draft[action.dataType] = dataViewState = {
                byId: {},
                dataViews: {},
                isLoading: {},
            };
        }

        dataViewState.isLoading[action.id] = true;
    },

    "Data/CompleteLoadingModel": (draft: Draft<DataState>, action: { dataType: DataType; model: any }) => {
        let dataViewState = draft[action.dataType];
        if (!dataViewState) {
            draft[action.dataType] = dataViewState = {
                byId: {},
                dataViews: {},
                isLoading: {},
            };
        }

        dataViewState.byId[action.model.id] = action.model;
        dataViewState.isLoading[action.model.id] = false;
    },

    "Data/BeginLoadingCollection": (draft: Draft<DataState>, action: { parameter: RequestParameter; dataType: DataType }) => {
        let dataViewState = draft[action.dataType];
        if (!dataViewState) {
            draft[action.dataType] = dataViewState = {
                byId: {},
                dataViews: {},
                isLoading: {},
            };
        }

        setDataViewLoading(dataViewState as any, action.parameter);
    },

    "Data/CompleteLoadingCollection": (draft: Draft<DataState>, action: { parameter?: RequestParameter; dataType: DataType; models: any[] }) => {
        let dataViewState = draft[action.dataType];
        if (!dataViewState) {
            draft[action.dataType] = dataViewState = {
                byId: {},
                dataViews: {},
                isLoading: {},
            };
        }

        setDataViewLoaded(dataViewState, action.parameter, action.models);
    },

    "Data/SetIssueSprintId": (draft: Draft<DataState>, action: { issueId: string; sprintId: undefined | string }) => {
        const issuesDataState = draft.issues!;

        const issue = issuesDataState.byId[action.issueId];
        const oldSprintId = issue.sprintId;
        issue.sprintId = action.sprintId;

        for (const dataViewKey of Object.keys(issuesDataState.dataViews)) {
            const isBacklog = dataViewKey.indexOf("/backlog") !== -1;
            const isMatchingNewSprint = dataViewKey.indexOf(`/sprint/${action.sprintId}/issue`) !== -1;
            const isMatchingOldSprint = dataViewKey.indexOf(`/sprint/${oldSprintId}/issue`) !== -1;
            const ids = issuesDataState.dataViews[dataViewKey].ids;
            if (!ids) {
                continue;
            }
            const indexOfIssueId = ids.indexOf(action.issueId);
            const addToArray = ((isBacklog && !action.sprintId) || (isMatchingNewSprint && action.sprintId)) && indexOfIssueId === -1;
            const removeFromArray = ((isBacklog && action.sprintId) || (isMatchingOldSprint && action.sprintId !== oldSprintId)) && indexOfIssueId !== -1;

            if (removeFromArray) {
                ids.splice(indexOfIssueId, 1);
            } else if (addToArray) {
                ids.push(action.issueId);
            }
        }
    },
};

function setDataViewLoading<T extends { id: string }>(dataViewState: DataViewState<T>, parameter: object) {
    dataViewState.dataViews[getDataViewKey(parameter)] = {
        isLoading: true,
        ids: undefined,
    };
}

function setDataViewLoaded<T extends { id: string }, DataViewModel extends DataView>(
    draft: Draft<DataViewState<T>>,
    parameter: object | undefined,
    models: T[],
) {
    const ids: string[] = [];
    for (const model of models) {
        ids.push(model.id);
        assignById(draft, model);
    }

    if (!parameter) {
        return;
    }

    const dataView: DataView = {
        isLoading: false,
        ids,
    };

    draft.dataViews[getDataViewKey(parameter)] = dataView;
}

function assignById<T extends { id: string }>(draft: Draft<DataViewState<T>>, value: T) {
    const existingValue = draft.byId[value.id] ?? {};
    assign(existingValue, value);
    draft.byId[value.id] = existingValue;
}

export const dataReducer = createTypedReducerWithImmer(initialState, reducer);
