import ClickOutside from "@pp/Components/Common/ClickOutside";
import { colors } from "@pp/Components/Common/Colors";
import { EpicTag } from "@pp/Components/Common/EpicTag";
import { IssueBlock } from "@pp/Components/Common/IssueBlock";
import { IssuePriority } from "@pp/Components/Common/IssuePriority";
import { IssueStatus } from "@pp/Components/Common/IssueStatus";
import { IssueType } from "@pp/Components/Common/IssueType";
import { Loading } from "@pp/Components/Common/Loading";
import MoreVertical from "@pp/Components/Icons/MoreVertical";
import { ApplicationState } from "@pp/Store/ApplicationState";
import { finishDraggingIssue, updateSprint } from "@pp/Store/Components/SprintPlanner/SprintPlannerActionCreators";
import { getById } from "@pp/Store/Data/DataSelectors";
import { IssueModel } from "@pp/Store/Types";
import * as React from "react";
import { connect, ResolveThunks } from "react-redux";
import styled from "styled-components";

interface OwnProps {
    issue: IssueModel;
}

const mapStateToProps = (state: ApplicationState, props: OwnProps) => {
    const sprintId = props.issue.sprintId;
    return {
        nextSprintId: sprintId ? getById(state.data.sprints, sprintId).value?.nextSprintId : undefined,
    };
};

const mapDispatchToProps = {
    finishDraggingIssue,
    updateSprint,
};

type Props = OwnProps & ReturnType<typeof mapStateToProps> & ResolveThunks<typeof mapDispatchToProps>;

interface State {
    optionsVisible?: boolean;
    removingIssue?: boolean;
}

class Component extends ClickOutside<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    dragEnd = () => {
        this.setState({
            removingIssue: true,
        });
        this.props.finishDraggingIssue(this.props.issue.id);
    };

    showOrHideOptions = () => {
        this.setState({
            optionsVisible: !this.state.optionsVisible,
        });
    };

    removeFromSprint = () => {
        this.setState({
            removingIssue: true,
            optionsVisible: false,
        });
        this.props.updateSprint(this.props.issue.id, null);
    };

    sendToNextSprint = () => {
        this.setState({
            removingIssue: true,
            optionsVisible: false,
        });
        this.props.updateSprint(this.props.issue.id, this.props.nextSprintId ?? null);
    };

    onClickOutside = () => {
        this.setState({
            optionsVisible: false,
        });
    };

    render() {
        const { issue } = this.props;

        return (
            <IssueStyle draggable={true} onDragEnd={this.dragEnd}>
                {this.state.removingIssue && (
                    <RemovingIssueStyle>
                        <Loading />
                    </RemovingIssueStyle>
                )}
                <TopStyle>
                    <HeadStyle>
                        <IssueSummaryStyle>{issue.summary}</IssueSummaryStyle>
                        <IssueBlockStyle issue={issue} />
                        <IssueShowOptionsStyle ref={this.setWrapperRef}>
                            <div onClick={this.showOrHideOptions}>
                                <MoreVertical size={20} />
                            </div>
                            {this.state.optionsVisible && (
                                <IssueOptionsStyle>
                                    {this.props.nextSprintId && <div onClick={this.sendToNextSprint}>Send to next sprint</div>}
                                    <div onClick={this.removeFromSprint}>Remove from sprint</div>
                                </IssueOptionsStyle>
                            )}
                        </IssueShowOptionsStyle>
                    </HeadStyle>
                    <div>
                        <EpicStyle>
                            <EpicTag epicId={issue.epicId} />
                        </EpicStyle>
                    </div>
                    <IssueStatusStyle statusId={issue.statusId} />
                </TopStyle>
                <FootStyle>
                    <IssueTypeStyle typeId={issue.typeId} />
                    <IssuePriorityStyle priorityId={issue.priorityId} />
                    <PointsStyle>{issue.points ?? 0}</PointsStyle>
                    <IssueLinkStyle href={issue.url} target="_blank">
                        {issue.key}
                    </IssueLinkStyle>
                </FootStyle>
            </IssueStyle>
        );
    }
}

const IssueStyle = styled.div`
    margin: 4px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0px 1px 2px 0px rgba(9, 30, 66, 0.25);
    padding: 10px;
    position: relative;
`;

const RemovingIssueStyle = styled.div`
    z-index: 1;
    border-radius: inherit;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const IssueSummaryStyle = styled.div``;

const IssueStatusStyle = styled(IssueStatus)`
    color: grey;
    margin-top: 8px;
    font-style: italic;
`;

const IssueBlockStyle = styled(IssueBlock)`
    top: 0;
    margin-left: auto;
    height: 100%;
    margin-top: 3px;
`;

const IssuePriorityStyle = styled(IssuePriority)`
    width: 20px;
    height: 20px;
`;

const IssueTypeStyle = styled(IssueType)`
    width: 20px;
    height: 20px;
`;

const IssueShowOptionsStyle = styled.div`
    margin-right: -5px;
    margin-top: 1px;
    cursor: pointer;
    position: relative;
`;

const IssueOptionsStyle = styled.div`
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 1;
    div {
        white-space: nowrap;
        padding: 5px 10px;
    }
    div:hover {
        background-color: ${colors.lightBackground};
        cursor: pointer;
    }
`;

const EpicStyle = styled.div`
    max-width: 100%;
    > div {
        margin: 8px 0;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
`;

const PointsStyle = styled.div`
    text-align: center;
    background-color: ${colors.pointsBackground};
    border-radius: 15px;
    color: ${colors.pointsText};
    padding: 0 8px;
`;

const IssueLinkStyle = styled.a`
    margin-left: auto;
    align-self: center;
`;

const TopStyle = styled.div`
    min-height: 50px;
`;

const HeadStyle = styled.div`
    display: flex;
`;

const FootStyle = styled.div`
    display: flex;
    margin-top: 16px;
`;

export const SprintPlannerIssue = connect(mapStateToProps, mapDispatchToProps)(Component);
