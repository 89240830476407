import Slash from "@pp/Components/Icons/Slash";
import { ApplicationState } from "@pp/Store/ApplicationState";
import { getById } from "@pp/Store/Data/DataSelectors";
import { IssueModel } from "@pp/Store/Types";
import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

interface OwnProps {
    className?: string;
    issue: IssueModel;
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    const blockingIssues = [];
    for (const id of ownProps.issue?.blockedBy) {
        const issue = getById(state.data.issues, id).value;
        if (issue) {
            blockingIssues.push(issue);
        }
    }

    return {
        blockingIssues,
    };
};

type Props = OwnProps & ReturnType<typeof mapStateToProps>;

class Component extends React.Component<Props> {
    render() {
        if (!this.props.issue || this.props.blockingIssues.length === 0) {
            return <div className={this.props.className} />;
        }

        return (
            <SpanStyle className={this.props.className}>
                <HoverPosition>
                    <HoverStyle>
                        <span>Blocked By</span>
                        {this.props.blockingIssues.map(o => (
                            <a key={o.key} href={o.url}>
                                {o.key}
                            </a>
                        ))}
                    </HoverStyle>
                </HoverPosition>
                <Slash color1="red" size={16} />
            </SpanStyle>
        );
    }
}

const HoverPosition = styled.div`
    display: none;
    padding-left: 4px;
    position: absolute;
    z-index: 1000;
    left: 100%;
    top: -12px;
`;

const HoverStyle = styled.div`
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.2);
    padding: 10px;
    span {
        display: block;
        white-space: nowrap;
    }
    a {
        display: block;
        white-space: nowrap;
    }
    a:first-child {
        margin-top: 4px;
    }
`;

const SpanStyle = styled.span`
    display: flex;
    align-items: center;
    position: relative;
    &:hover ${HoverPosition} {
        display: block;
    }
`;

export const IssueBlock = connect(mapStateToProps)(Component);
