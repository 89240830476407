import { SprintPlannerColumn } from "@pp/Components/SprintPlanner/SprintPlannerColumn";
import { getSprintIssuesDataView, getSprintIssuesParameter } from "@pp/Store/Components/SprintPlanner/SprintPlannerSelectors";
import { loadIssues } from "@pp/Store/Data/ActionCreators/LoadIssues";
import { getBoardAssigneeField, getBoardTeamMembersField } from "@pp/Store/Data/PropertySelectors";
import * as React from "react";
import styled from "styled-components";
import { ApplicationState } from "@pp/Store/ApplicationState";
import { connect, ResolveThunks } from "react-redux";
import { Dictionary } from "@pp/Common/Types";
import { IssueModel } from "@pp/Store/Types";

const mapStateToProps = (state: ApplicationState) => {
    const parameter = getSprintIssuesParameter(state);
    return {
        selectedSprintId: state.components.sprintPlanner.selectedSprintId,
        boardAssigneeField: getBoardAssigneeField(state, state.context.selectedBoardId).value,
        boardTeamMembersField: getBoardTeamMembersField(state, state.context.selectedBoardId).value,
        parameter,
        issuesDataView: getSprintIssuesDataView(state),
    };
};

const mapDispatchToProps = {
    loadIssues,
};

type Props = ResolveThunks<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

class SprintPlannerIssuesComponent extends React.Component<Props> {
    componentDidMount(): void {
        this.loadIssuesIfNeeded();

        window.addEventListener("focus", this.windowFocus);
    }

    componentWillUnmount() {
        window.removeEventListener("focus", this.windowFocus);
    }

    windowFocus = () => {
        if (this.props.parameter) {
            this.props.loadIssues(this.props.parameter, true);
        }
    };

    componentDidUpdate() {
        this.loadIssuesIfNeeded();
    }

    loadIssuesIfNeeded() {
        if (!this.props.issuesDataView.value && !this.props.issuesDataView.isLoading && this.props.parameter) {
            this.props.loadIssues(this.props.parameter);
        }
    }

    render() {
        if (!this.props.issuesDataView.value || !this.props.boardAssigneeField || !this.props.boardTeamMembersField) {
            return null;
        }

        const accountIds: string[] = [];
        accountIds.push("");
        if (this.props.boardTeamMembersField.value) {
            for (const teamMemberAccountId of this.props.boardTeamMembersField.value) {
                accountIds.push(teamMemberAccountId);
            }
        }

        const issuesByAssigneeId: Dictionary<IssueModel[]> = {};
        issuesByAssigneeId[""] = [];
        for (const issue of this.props.issuesDataView.value) {
            const assigneeId = issue.userFields[this.props.boardAssigneeField.value ?? ""] ?? "";
            let issues = issuesByAssigneeId[assigneeId];
            if (!issues) {
                issuesByAssigneeId[assigneeId] = issues = [];
                if (!accountIds.includes(assigneeId)) {
                    accountIds.push(assigneeId);
                }
            }

            issues.push(issue);
        }

        return (
            <Scroller>
                <Container>
                    {accountIds.map(o => (
                        <SprintPlannerColumn key={o} assigneeId={o} issues={issuesByAssigneeId[o] ?? []} />
                    ))}
                </Container>
            </Scroller>
        );
    }
}

const Scroller = styled.div`
    overflow: scroll;
    height: 100%;
`;

const Container = styled.div`
    display: table;
    border-spacing: 5px;
    align-items: stretch;
    height: 100%;
`;

export const SprintPlannerColumns = connect(mapStateToProps, mapDispatchToProps)(SprintPlannerIssuesComponent);
