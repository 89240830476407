import { Modal } from "@pp/Components/Common/Modal";
import { ApplicationState } from "@pp/Store/ApplicationState";
import { closeBoardSettings, removeBoardAssigneeField, setBoardAssigneeField } from "@pp/Store/Components/BoardSettings/BoardSettingsActionCreators";
import { loadFieldsIfNeeded, userFields } from "@pp/Store/Data/MapIssue";
import { getBoardAssigneeField } from "@pp/Store/Data/PropertySelectors";
import * as React from "react";
import { connect, ResolveThunks } from "react-redux";

const mapStateToProps = (state: ApplicationState) => ({
    isOpen: state.components.boardSettings.isOpen,
    boardAssigneeField: getBoardAssigneeField(state, state.context.selectedBoardId).value,
});

const mapDispatchToProps = {
    closeBoardSettings,
    setBoardAssigneeField,
    removeBoardAssigneeField,
};

type Props = ResolveThunks<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

class Component extends React.Component<Props> {
    componentDidMount() {
        loadFieldsIfNeeded();
    }

    onChange = (event: React.FormEvent<HTMLSelectElement>) => {
        this.props.setBoardAssigneeField(event.currentTarget.value);
    };

    render() {
        if (!userFields) {
            return null;
        }

        return (
            <Modal isOpen={this.props.isOpen} close={this.props.closeBoardSettings} title="Board Settings">
                <div className="ak-field-group">
                    <label>Custom Assignee Field</label>
                    <select className="ak-field-select" onChange={this.onChange} value={this.props.boardAssigneeField?.value}>
                        {userFields.map(userField => (
                            <option value={userField.id} key={userField.id}>
                                {userField.name}
                            </option>
                        ))}
                    </select>
                </div>
                {IS_DEBUG && (
                    <div>
                        <br />
                        <button className="ak-button" onClick={() => this.props.removeBoardAssigneeField()}>
                            Remove ppAssigneeField
                        </button>
                    </div>
                )}
            </Modal>
        );
    }
}

export const BoardSettingsModal = connect(mapStateToProps, mapDispatchToProps)(Component);
