import { ApplicationState } from "@pp/Store/ApplicationState";
import { getDataView } from "@pp/Store/Data/DataSelectors";

export function getSelectedSprintId(state: ApplicationState) {
    return state.components.sprintPlanner.selectedSprintId;
}

export function getSprintIssuesParameter(state: ApplicationState) {
    let selectedSprintId = getSelectedSprintId(state);
    if (!selectedSprintId) {
        return;
    }

    return {
        url: `/rest/agile/1.0/board/${state.context.selectedBoardId}/sprint/${selectedSprintId}/issue`,
    };
}

export function getSprintIssuesDataView(state: ApplicationState) {
    return getDataView(state.data.issues, getSprintIssuesParameter(state));
}
