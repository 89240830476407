import { request } from "@pp/Atlassian/Request";
import { AppThunkAction } from "@pp/Store/AppThunkAction";
import { mapAccount } from "@pp/Store/Data/MapAccount";

export const loadAccount = (accountId: string): AppThunkAction => dispatch => {
    (async () => {
        dispatch({
            type: "Data/BeginLoadingModel",
            dataType: "accounts",
            id: accountId,
        });

        const accountApiModel: AccountApiModel = await request({
            url: "/rest/api/3/user?accountId=" + accountId,
        });

        const account = mapAccount(accountApiModel);

        dispatch({
            type: "Data/CompleteLoadingModel",
            dataType: "accounts",
            model: account,
        });
    })();
};
