import { Modal } from "@pp/Components/Common/Modal";
import { ApplicationState } from "@pp/Store/ApplicationState";
import { addTeamMember, closeAddTeamMember, updateTeamMemberSearch } from "@pp/Store/Components/SprintPlanner/SprintPlannerActionCreators";
import * as React from "react";
import { connect, ResolveThunks } from "react-redux";
import styled from "styled-components";

const mapStateToProps = (state: ApplicationState) => ({
    isOpen: state.components.sprintPlanner.isAddTeamMemberOpen,
    teamMemberSearch: state.components.sprintPlanner.teamMemberSearch,
    userData: state.components.sprintPlanner.teamMemberUserData,
});

const mapDispatchToProps = {
    closeAddTeamMember,
    updateTeamMemberSearch,
    addTeamMember,
};

type Props = ResolveThunks<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

class Component extends React.Component<Props> {
    onChange = (event: React.FormEvent<HTMLInputElement>) => {
        this.props.updateTeamMemberSearch(event.currentTarget.value);
    };

    render() {
        return (
            <Modal isOpen={this.props.isOpen} close={this.props.closeAddTeamMember} title="Add Team Member">
                <div className="ak-field-group">
                    <SearchBoxLabel>Search for User</SearchBoxLabel>
                    <SearchBox className="ak-field-text" type="text" onChange={this.onChange} value={this.props.teamMemberSearch} />
                    <UsersList>
                        {this.props.userData?.users?.map(user => (
                            <UserRow key={user.accountId}>
                                <button onClick={() => this.props.addTeamMember(user.accountId)} className="ak-button ak-button__appearance-primary">
                                    Select
                                </button>
                                <img src={user.avatarUrl} />
                                <div dangerouslySetInnerHTML={{ __html: user.html }}></div>
                            </UserRow>
                        ))}
                    </UsersList>
                </div>
            </Modal>
        );
    }
}

export const AddTeamMemberModal = connect(mapStateToProps, mapDispatchToProps)(Component);

const SearchBoxLabel = styled.label``;

const SearchBox = styled.input``;

const UsersList = styled.div`
    height: 200px;
`;

const UserRow = styled.div`
    display: flex;
    align-items: center;
    padding: 4px;
    > img {
        margin: 0 5px;
        height: 24px;
        width: 24px;
    }
`;
