import { AddTeamMemberModal } from "@pp/Components/SprintPlanner/AddTeamMemberModal";
import { SprintPlannerHeader } from "@pp/Components/SprintPlanner/SprintPlannerHeader";
import * as React from "react";
import styled from "styled-components";
import { SprintPlannerColumns } from "@pp/Components/SprintPlanner/SprintPlannerColumns";

export class SprintPlannerContainer extends React.Component {
    render() {
        return (
            <SprintPlannerStyle>
                <SprintPlannerHeader />
                <SprintPlannerColumns />
                <AddTeamMemberModal />
            </SprintPlannerStyle>
        );
    }
}

const SprintPlannerStyle = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;
