import { request } from "@pp/Atlassian/Request";
import { AppThunkAction } from "@pp/Store/AppThunkAction";
import { updateProperty } from "@pp/Store/Data/ActionCreators/UpdateProperty";
import { BoardProperties, getBoardAssigneeFieldUrl } from "@pp/Store/Data/PropertySelectors";
import { AnyAction } from "@pp/Store/Reducers";

export const openBoardSettings = (): AnyAction => ({
    type: "Components/BoardSettings/Open",
});

export const closeBoardSettings = (): AnyAction => ({
    type: "Components/BoardSettings/Close",
});

export const setBoardAssigneeField = (fieldId: string): AppThunkAction => (dispatch, getState) => {
    const selectedBoardId = getState().context.selectedBoardId;
    dispatch(updateProperty(selectedBoardId, BoardProperties.ppAssigneeField, fieldId));
};

export const removeBoardAssigneeField = (): AppThunkAction => (dispatch, getState) => {
    (async () => {
        const selectedBoardId = getState().context.selectedBoardId;

        let url = getBoardAssigneeFieldUrl(selectedBoardId);

        await request({
            type: "DELETE",
            url,
        });

        dispatch({
            type: "Data/CompleteLoadingModel",
            dataType: "properties",
            model: {
                id: url,
                value: undefined,
            },
        });
    })();
};
