export function getItem(key: string) {
    try {
        return window.localStorage.getItem(key);
    } catch (exception) {
        console.warn("Unable to retrieve from local storage, exception was " + exception);
    }
}

export function setItem(key: string, value: string) {
    try {
        window.localStorage.setItem(key, value);
    } catch (exception) {
        console.warn("Unable to set local storage, exception was " + exception);
    }
}

export function removeItem(key: string) {
    try {
        window.localStorage.removeItem(key);
    } catch (exception) {
        console.warn("Unable to remove local storage, exception was " + exception);
    }
}
