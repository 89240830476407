import * as React from "react";
import styled, { keyframes } from "styled-components";

export const Loading = () => (
    <LoadingStyle viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg" height="40px" width="40px">
        <circle
            cx="33"
            cy="33"
            r="30"
            fill="none"
            stroke="#333333"
            strokeWidth="6px"
            strokeLinecap="round"
            strokeDashoffset="0"
            strokeDasharray={dashOffset + "px"}
        />
    </LoadingStyle>
);

const duration = "1.4s";
const dashOffset = 187;

const rotator = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(270deg);
    }
`;

const dash = keyframes`
    0% {
        stroke-dashoffset: ${dashOffset}px;
    }
    50% {
        stroke-dashoffset: ${dashOffset / 4}px;
        transform: rotate(135deg);
    }
    100% {
        stroke-dashoffset: ${dashOffset}px;
        transform: rotate(450deg);
    }
`;

const LoadingStyle = styled.svg`
    animation: ${rotator} ${duration} linear infinite;
    circle {
        animation: ${dash} ${duration} ease-in-out infinite;
        transform-origin: center;
    }
`;
