import * as React from "react";

const X: React.FC<{ size?: number; color1?: string; className?: string }> = ({ size = 24, color1 = "currentColor", className }) => (
    <svg
        className={className}
        focusable="false"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        fill="none"
        stroke={color1}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <path d="M18 6L6 18M6 6l12 12" />
    </svg>
);

export default React.memo(X);
