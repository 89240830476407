import { Dictionary } from "@pp/Common/Types";
import { ApplicationState } from "@pp/Store/ApplicationState";
import { getById } from "@pp/Store/Data/DataSelectors";
import { connect } from "react-redux";
import * as React from "react";
import styled from "styled-components";

interface OwnProps {
    epicId?: string;
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => ({
    epic: getById(state.data.epics, ownProps.epicId).value,
});

type Props = ReturnType<typeof mapStateToProps> & OwnProps;

class Component extends React.Component<Props> {
    render() {
        if (!this.props.epic) {
            return null;
        }

        const epicColors = getEpicColors(this.props.epic);

        return <EpicStyle {...epicColors}>{this.props.epic.name}</EpicStyle>;
    }
}

export const EpicTag = connect(mapStateToProps)(Component);

const EpicStyle = styled.div<{ backgroundColor: string; color: string }>`
    background-color: ${props => props.backgroundColor};
    color: ${props => props.color};
    border-radius: 4px;
    padding: 2px 6px;
`;

export function getEpicColors(epic: { color: string }) {
    const color = colors[epic.color] ?? "black";
    const backgroundColor = backgroundColors[epic.color] ?? "#888";
    return {
        color,
        backgroundColor,
    };
}

const colors: Dictionary<string> = {
    color_1: "white",
};

const backgroundColors: Dictionary<string> = {
    color_13: "#57D9A3",
    color_5: "#00C7E6",
    color_4: "#4C9AFF",
    color_8: "#998DD9",
    color_14: "#FF8F73",
    color_2: "#FFC400",
    color_1: "#42526E",
    color_6: "#79f2c0",
    color_11: "#79E2F2",
    color_10: "#B3D4FF",
    color_7: "#C0B6F2",
    color_9: "#FFBDAD",
    color_3: "#FFE380",
    color_12: "#EBECF0",
};
