import { backlogReducer } from "@pp/Store/Components/Backlog/BacklogReducer";
import { boardSettingsReducer } from "@pp/Store/Components/BoardSettings/BoardSettingsReducer";
import { sprintPlannerReducer } from "@pp/Store/Components/SprintPlanner/SprintPlannerReducer";
import { combineReducers } from "redux";

const reducers = {
    backlog: backlogReducer,
    boardSettings: boardSettingsReducer,
    sprintPlanner: sprintPlannerReducer,
};

export type ComponentsReducers = Readonly<typeof reducers>;

export const componentsReducer = combineReducers(reducers as any);
