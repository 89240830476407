import { request, RequestParameter } from "@pp/Atlassian/Request";
import { AppThunkAction } from "@pp/Store/AppThunkAction";
import { SprintModel } from "@pp/Store/Types";

export const loadSprints = (parameter: RequestParameter): AppThunkAction => {
    return dispatch => {
        dispatch({
            type: "Data/BeginLoadingCollection",
            dataType: "sprints",
            parameter,
        });

        (async () => {
            const data: { values: SprintApiModel[] } = await request({
                ...parameter,
                type: "GET",
            });

            const models: SprintModel[] = data.values.map(apiModel => ({
                id: apiModel.id.toString(),
                name: apiModel.name,
                endDate: new Date(apiModel.endDate),
                startDate: new Date(apiModel.startDate),
                originBoardId: apiModel.originBoardId.toString(),
                self: apiModel.self,
            }));

            for (let x = 0; x < models.length - 1; x++) {
                models[x].nextSprintId = models[x + 1].id;
            }

            dispatch({
                type: "Data/CompleteLoadingCollection",
                dataType: "sprints",
                models,
                parameter,
            });
        })();
    };
};
