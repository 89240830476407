import { Dictionary } from "@pp/Common/Types";
import { waitFor } from "@pp/Common/WaitFor";

declare let AP: Request;

interface Request {
    request: any;
}

export interface RequestParameter {
    url: string;
    data?: Dictionary<any>;
}

interface RequestOptions extends RequestParameter {
    type?: "POST" | "GET" | "PUT" | "DELETE";
}

const getAP = async () => {
    if ((window as any).useAP) {
        await waitFor(() => typeof AP !== "undefined");
        return AP;
    }
};

export async function request<ResponseData = void>(options: RequestOptions): Promise<ResponseData> {
    const AP = await getAP();
    if (AP) {
        return new Promise<ResponseData>(resolve => {
            const data = options.type === "GET" || !options.data ? undefined : JSON.stringify(options.data);
            let url = options.url;
            if (options.data && options.type === "GET") {
                if (url.indexOf("?") > -1) {
                    url += "&";
                } else {
                    url += "?";
                }

                url += serialize(options.data);
            }
            AP.request({
                url,
                type: options.type,
                data,
                contentType: options.data ? "application/json" : undefined,
            })
                .then((result: { body: string }) => {
                    resolve(!result || !result.body ? {} : JSON.parse(result.body));
                })
                .catch((result: { err: string }) => {
                    resolve(!result || !result.err ? {} : JSON.parse(result.err));
                });
        });
    } else {
        const encoded = btoa(JIRA_USERNAME_PASSWORD);
        const headers: Record<string, string> = {
            Authorization: "Basic " + encoded,
            Accept: "application/json",
        };

        const init: RequestInit = {
            method: options.type ? options.type : "GET",
            headers,
        };

        let url = options.url;
        const method = options.type ? options.type : "GET";
        if (options.data) {
            if (method === "GET") {
                url +=
                    "?" +
                    Object.keys(options.data)
                        .map(o => `${o}=${encodeURIComponent((options.data as Dictionary<any>)![o]!)}`)
                        .join("&");
            } else {
                init.body = JSON.stringify(options.data);
                headers["Content-Type"] = "application/json";
            }
        }

        const response = await fetch(url, init);
        if (response.status === 204) {
            return {} as ResponseData;
        }

        return response.text().then(text => {
            return text ? JSON.parse(text) : {};
        });
    }
}

function serialize(data: Dictionary<any>, prefix?: string): string {
    const queryStringParameters = [];
    for (const property in data) {
        if (data.hasOwnProperty(property)) {
            const key = prefix ? prefix + "[" + property + "]" : property;
            const value = data[property];
            queryStringParameters.push(
                value !== null && typeof value === "object" ? serialize(value, key) : encodeURIComponent(key) + "=" + encodeURIComponent(value),
            );
        }
    }
    return queryStringParameters.join("&");
}
