import { Dictionary } from "@pp/Common/Types";
import { BacklogEpic } from "@pp/Components/Backlog/BacklogEpic";
import { ApplicationState } from "@pp/Store/ApplicationState";
import { setHoveringOverBacklog } from "@pp/Store/Components/SprintPlanner/SprintPlannerActionCreators";
import { getDataView } from "@pp/Store/Data/DataSelectors";
import { IssueModel } from "@pp/Store/Types";
import { connect, ResolveThunks } from "react-redux";
import * as React from "react";
import { loadIssues } from "@pp/Store/Data/ActionCreators/LoadIssues";
import styled from "styled-components";

export const getBoardParameter = (boardId: string) => {
    if (!boardId) {
        return;
    }

    return {
        url: `/rest/agile/1.0/board/${boardId}/backlog`,
        data: {
            jql: "issueType != Epic",
        },
    };
};

const mapStateToProps = (state: ApplicationState) => {
    const issuesParameter = getBoardParameter(state.context.selectedBoardId);
    return {
        issuesParameter,
        selectedEpicId: state.components.backlog.selectedEpicId,
        selectedPriorityId: state.components.backlog.selectedPriorityId,
        selectedProjectKey: state.components.backlog.selectedProjectKey,
        hideUnpointed: state.components.backlog.hideUnpointed,
        hideBlocked: state.components.backlog.hideBlocked,
        search: state.components.backlog.search,
        issuesDataView: getDataView(state.data.issues, issuesParameter),
        isHoveringOverBacklog: state.components.backlog.isHoveringOverBacklog,
    };
};

const mapDispatchToProps = {
    loadIssues,
    setHoveringOverBacklog,
};

type Props = ReturnType<typeof mapStateToProps> & ResolveThunks<typeof mapDispatchToProps>;

class Component extends React.Component<Props> {
    dragOver = () => {
        this.props.setHoveringOverBacklog();
    };

    componentDidMount(): void {
        if (!this.props.issuesDataView.value && !this.props.issuesDataView.isLoading && this.props.issuesParameter) {
            this.props.loadIssues(this.props.issuesParameter);
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (
            !this.props.issuesDataView.value &&
            !this.props.issuesDataView.isLoading &&
            this.props.issuesParameter &&
            this.props.issuesParameter.url !== prevProps.issuesParameter?.url
        ) {
            this.props.loadIssues(this.props.issuesParameter);
        }
    }

    render() {
        if (!this.props.issuesDataView.value) {
            return null;
        }

        const loweredSearch = this.props.search?.toLowerCase();
        const projectKey = this.props.selectedProjectKey ? this.props.selectedProjectKey + "-" : undefined;

        const issuesByEpicId: Dictionary<IssueModel[]> = {};
        for (const issue of this.props.issuesDataView.value) {
            const epicId = issue.epicId ?? "-1";

            if (this.props.selectedEpicId && epicId !== this.props.selectedEpicId) {
                continue;
            }
            if (this.props.selectedPriorityId && issue.priorityId !== this.props.selectedPriorityId) {
                continue;
            }
            if (this.props.hideUnpointed && !issue.points) {
                continue;
            }
            if (this.props.hideBlocked && issue.blockedBy.length > 0) {
                continue;
            }
            if (projectKey && !issue.key.startsWith(projectKey)) {
                continue;
            }
            if (loweredSearch && issue.summary.toLowerCase().indexOf(loweredSearch) === -1 && issue.key.indexOf(loweredSearch) === -1) {
                continue;
            }

            if (!issuesByEpicId[epicId]) {
                issuesByEpicId[epicId] = [];
            }

            issuesByEpicId[epicId]!.push(issue);
        }

        return (
            <BacklogEpicsContainerStyle onDragOver={this.dragOver}>
                {Object.keys(issuesByEpicId).map(epicId => {
                    return <BacklogEpic key={epicId} epicId={epicId} issues={issuesByEpicId[epicId]!} />;
                })}
                {this.props.isHoveringOverBacklog && <DropIssueStyle />}
            </BacklogEpicsContainerStyle>
        );
    }
}

const BacklogEpicsContainerStyle = styled.div`
    height: 100%;
    overflow-y: scroll;
`;

const DropIssueStyle = styled.div`
    background-color: #888;
    height: 40px;
`;

export const BacklogEpicsContainer = connect(mapStateToProps, mapDispatchToProps)(Component);
