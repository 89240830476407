import { request, RequestParameter } from "@pp/Atlassian/Request";
import { AppThunkAction } from "@pp/Store/AppThunkAction";

export const loadProjects = (parameter: RequestParameter): AppThunkAction => {
    return dispatch => {
        dispatch({
            type: "Data/BeginLoadingCollection",
            dataType: "projects",
            parameter,
        });

        (async () => {
            const data: { values: ProjectApiModel[] } = await request({
                ...parameter,
                type: "GET",
            });

            const models = data.values.map(apiModel => ({
                id: apiModel.id.toString(),
                name: apiModel.name,
                key: apiModel.key,
            }));

            dispatch({
                type: "Data/CompleteLoadingCollection",
                dataType: "projects",
                models,
                parameter,
            });
        })();
    };
};
