import { getItem } from "@pp/Common/LocalStorage";
import Settings from "@pp/Components/Icons/Settings";
import { BoardSettingsModal } from "@pp/Components/Main/BoardSettingsModal";
import { ApplicationState } from "@pp/Store/ApplicationState";
import { openBoardSettings } from "@pp/Store/Components/BoardSettings/BoardSettingsActionCreators";
import { setSelectedBoardId } from "@pp/Store/Context/ContextActionCreators";
import { loadBoards } from "@pp/Store/Data/ActionCreators/LoadBoards";
import { loadProperty } from "@pp/Store/Data/ActionCreators/LoadProperty";
import { getDataView } from "@pp/Store/Data/DataSelectors";
import { getBoardAssigneeFieldUrl, getBoardTeamMembersFieldUrl } from "@pp/Store/Data/PropertySelectors";
import * as React from "react";
import { connect, ResolveThunks } from "react-redux";
import styled from "styled-components";
import sortBy from "lodash/sortBy";

const boardParameter = {
    url: `/rest/agile/1.0/board?type=scrum`,
};

const mapStateToProps = (state: ApplicationState) => ({
    selectedBoardId: state.context.selectedBoardId,
    boardsDataView: getDataView(state.data.boards, boardParameter),
});

const mapDispatchToProps = {
    loadBoards,
    loadProperty,
    setSelectedBoardId,
    openBoardSettings,
};

type Props = ResolveThunks<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

class Component extends React.Component<Props> {
    componentDidMount() {
        const selectedBoardId = getItem("SelectedBoardId");
        if (selectedBoardId) {
            this.props.setSelectedBoardId(selectedBoardId);
            this.loadBoardAssigneeField(selectedBoardId);
        }

        if (!this.props.boardsDataView.value && !this.props.boardsDataView.isLoading) {
            this.props.loadBoards(boardParameter);
        }
    }

    loadBoardAssigneeField = (selectedBoardId: string) => {
        this.props.loadProperty(getBoardAssigneeFieldUrl(selectedBoardId));
        this.props.loadProperty(getBoardTeamMembersFieldUrl(selectedBoardId));
    };

    onChange = (event: React.FormEvent<HTMLSelectElement>) => {
        this.props.setSelectedBoardId(event.currentTarget.value);
        this.loadBoardAssigneeField(event.currentTarget.value);
    };

    onClick = () => {
        this.props.openBoardSettings();
    };

    render() {
        if (!this.props.boardsDataView.value) {
            return <HeaderStyle />;
        }

        return (
            <HeaderStyle>
                <select value={this.props.selectedBoardId} onChange={this.onChange} className="ak-field-select">
                    <option value="">Select Board</option>
                    {sortBy(this.props.boardsDataView.value, ["project.key", "name"]).map(o => (
                        <option key={o.id} value={o.id}>
                            {o.project.key}: {o.name}
                        </option>
                    ))}
                </select>
                {this.props.selectedBoardId && (
                    <BoardSettings onClick={this.onClick}>
                        <Settings />
                    </BoardSettings>
                )}
                <BoardSettingsModal />
            </HeaderStyle>
        );
    }
}

export const MainHeader = connect(mapStateToProps, mapDispatchToProps)(Component);

const HeaderStyle = styled.div`
    grid-column: 1 / 3;
    flex-basis: 100%;
    height: 60px;
    padding: 10px;
    display: flex;
    padding: 10px;
    align-items: center;
    position: relative;
    z-index: 3;
    &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        height: 4px;
        background: linear-gradient(180deg, rgba(9, 30, 66, 0.13) 0, rgba(9, 30, 66, 0.13) 1px, rgba(9, 30, 66, 0.08) 1px, rgba(9, 30, 66, 0) 4px);
    }

    > select {
        width: 200px;
    }
`;

const BoardSettings = styled.button`
    background-color: transparent;
    border: none;
    margin-left: 10px;
    cursor: pointer;
    &:active,
    &:focus {
        outline: none;
    }
`;
