import { componentsReducer, ComponentsReducers } from "@pp/Store/Components/ComponentsReducer";
import { contextReducer } from "@pp/Store/Context/ContextReducer";
import { dataReducer } from "@pp/Store/Data/DataReducer";

export const reducers = {
    context: contextReducer,
    components: componentsReducer,
    data: dataReducer,
};

type Reducers = Omit<typeof reducers, "components">;

export type AnyAction = Parameters<Reducers[keyof Reducers]>[1] | Parameters<ComponentsReducers[keyof ComponentsReducers]>[1];
