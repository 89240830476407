import { AnyAction } from "@pp/Store/Reducers";

export const setSelectedEpicId = (value: string): AnyAction => ({
    type: "Components/Backlog/SetSelectedEpicId",
    value,
});

export const setSelectedPriorityId = (value: string): AnyAction => ({
    type: "Components/Backlog/SetSelectedPriorityId",
    value,
});

export const setSelectedProjectKey = (value: string): AnyAction => ({
    type: "Components/Backlog/SetSelectedProjectKey",
    value,
});

export const setHideUnpointed = (value: boolean): AnyAction => ({
    type: "Components/Backlog/SetHideUnpointed",
    value,
});

export const setHidePointed = (value: boolean): AnyAction => ({
    type: "Components/Backlog/SetHidePointed",
    value,
});

export const setHideBlocked = (value: boolean): AnyAction => ({
    type: "Components/Backlog/SetHideBlocked",
    value,
});

export const setSearch = (value: string): AnyAction => ({
    type: "Components/Backlog/SetSearch",
    value,
});
