import { colors } from "@pp/Components/Common/Colors";
import { MainHeader } from "@pp/Components/Main/MainHeader";
import * as React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { BacklogContainer } from "@pp/Components/Backlog/BacklogContainer";
import { SprintPlannerContainer } from "@pp/Components/SprintPlanner/SprintPlannerContainer";

export const Layout = () => {
    return (
        <>
            <GlobalStyle />
            <ContainerStyle>
                <MainHeader />
                <LeftStyle>
                    <BacklogContainer />
                    <BorderThing />
                </LeftStyle>
                <RightStyle>
                    <SprintPlannerContainer />
                </RightStyle>
            </ContainerStyle>
        </>
    );
};

const GlobalStyle = createGlobalStyle`
    body {
        color: ${colors.basicallyBlack};
    }
`;

const ContainerStyle = styled.div`
    display: grid;
    width: 100vw;
    min-height: 100vh;
    height: 100vh;
    grid-template-rows: 60px calc(100vh - 60px);
    grid-template-columns: 400px calc(100vw - 400px);
`;

const LeftStyle = styled.div`
    background-color: ${colors.lightBackground};
    width: 100%;
    position: relative;
    height: 100%;
`;

const BorderThing = styled.div`
    background: linear-gradient(to left, rgba(0, 0, 0, 0.2) 0px, rgba(0, 0, 0, 0.2) 1px, rgba(0, 0, 0, 0.1) 1px, rgba(0, 0, 0, 0) 100%);
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 4px;
    z-index: 3;
`;

const RightStyle = styled.div`
    width: 100%;
    padding-left: 20px;
    height: 100%;
`;
