import { RequestParameter } from "@pp/Atlassian/Request";
import { getBoardParameter } from "@pp/Components/Backlog/BacklogEpicsContainer";
import { ApplicationState } from "@pp/Store/ApplicationState";
import {
    setHideBlocked,
    setHidePointed,
    setHideUnpointed,
    setSearch,
    setSelectedEpicId,
    setSelectedPriorityId,
    setSelectedProjectKey,
} from "@pp/Store/Components/Backlog/BacklogActionCreators";
import { loadProjects } from "@pp/Store/Data/ActionCreators/LoadProjects";
import { getDataView } from "@pp/Store/Data/DataSelectors";
import sortBy from "lodash/sortBy";
import { connect, ResolveThunks } from "react-redux";
import * as React from "react";
import styled from "styled-components";

const projectsParameter: RequestParameter = {
    url: "/rest/api/3/project/search?maxResults=200",
};

const mapStateToProps = (state: ApplicationState) => {
    const parameter = getBoardParameter(state.context.selectedBoardId);
    return {
        search: state.components.backlog.search,
        hideUnpointed: state.components.backlog.hideUnpointed,
        hidePointed: state.components.backlog.hidePointed,
        hideBlocked: state.components.backlog.hideBlocked,
        selectedEpicId: state.components.backlog.selectedEpicId,
        selectedPriorityId: state.components.backlog.selectedPriorityId,
        selectedProjectKey: state.components.backlog.selectedProjectKey,
        epics: getDataView(state.data.epics, parameter).value ?? [],
        priorities: getDataView(state.data.priorities, parameter).value ?? [],
        projects: getDataView(state.data.projects, projectsParameter).value,
    };
};

const mapDispatchToProps = {
    setSelectedEpicId,
    setSelectedPriorityId,
    setSelectedProjectKey,
    setHideUnpointed,
    setHidePointed,
    setHideBlocked,
    setSearch,
    loadProjects,
};

type Props = ReturnType<typeof mapStateToProps> & ResolveThunks<typeof mapDispatchToProps>;

interface State {
    search: string;
}

class Component extends React.Component<Props, State> {
    private timer?: number;

    constructor(props: Props) {
        super(props);

        this.state = {
            search: "",
        };
    }

    componentDidMount() {
        this.props.loadProjects(projectsParameter);
    }

    onChangeEpic = (event: React.FormEvent<HTMLSelectElement>) => {
        this.props.setSelectedEpicId(event.currentTarget.value);
    };

    onChangePriority = (event: React.FormEvent<HTMLSelectElement>) => {
        this.props.setSelectedPriorityId(event.currentTarget.value);
    };

    onChangeProject = (event: React.FormEvent<HTMLSelectElement>) => {
        this.props.setSelectedProjectKey(event.currentTarget.value);
    };

    onChangeHideUnpointed = () => {
        this.props.setHideUnpointed(!this.props.hideUnpointed);
    };

    onChangeHidePointed = () => {
        this.props.setHidePointed(!this.props.hidePointed);
    };

    onChangeHideBlocked = () => {
        this.props.setHideBlocked(!this.props.hideBlocked);
    };

    onChangeSearch = (event: React.FormEvent<HTMLInputElement>) => {
        const search = event.currentTarget.value;
        this.setState({
            search,
        });
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.props.setSearch(search);
        }, 50);
    };

    render() {
        return (
            <ContainerStyle>
                <FilterStyle className="ak-field-group">
                    <label className="ak-">Search</label>
                    <input className="ak-field-text" type="text" value={this.state.search} onChange={this.onChangeSearch} />
                </FilterStyle>
                <FilterStyle className="ak-field-group">
                    <label className="ak-">Epic</label>
                    <select className="ak-field-select" value={this.props.selectedEpicId} onChange={this.onChangeEpic}>
                        <option value="">Select Epic</option>
                        <option value="-1">No Epic</option>
                        {sortBy(this.props.epics, ["name"]).map(o => (
                            <option key={o.id} value={o.id}>
                                {o.name}
                            </option>
                        ))}
                    </select>
                </FilterStyle>
                <FilterStyle className="ak-field-group">
                    <label className="ak-">Priority</label>
                    <select className="ak-field-select" value={this.props.selectedPriorityId} onChange={this.onChangePriority}>
                        <option value="">Select Priority</option>
                        {sortBy(this.props.priorities, ["name"]).map(o => (
                            <option key={o.id} value={o.id}>
                                {o.name}
                            </option>
                        ))}
                    </select>
                </FilterStyle>
                <FilterStyle className="ak-field-group">
                    <label className="ak-">Project</label>
                    <select className="ak-field-select" value={this.props.selectedProjectKey} onChange={this.onChangeProject}>
                        <option value="">Select Project</option>
                        {sortBy(this.props.projects, ["name"]).map(o => (
                            <option key={o.key} value={o.key}>
                                {o.key} - {o.name}
                            </option>
                        ))}
                    </select>
                </FilterStyle>
                <FilterStyle className="ak-field-group" data-hascheckbox>
                    <label>
                        <input type="checkbox" checked={this.props.hideUnpointed} onChange={this.onChangeHideUnpointed} /> Hide Unpointed
                    </label>
                    <label>
                        <input type="checkbox" checked={this.props.hidePointed} onChange={this.onChangeHidePointed} /> Hide pointed
                    </label>
                </FilterStyle>
                <FilterStyle className="ak-field-group" data-hascheckbox>
                    <label>
                        <input type="checkbox" checked={this.props.hideBlocked} onChange={this.onChangeHideBlocked} /> Hide Blocked
                    </label>
                </FilterStyle>
            </ContainerStyle>
        );
    }
}

const ContainerStyle = styled.div`
    padding: 10px 10px 20px;
`;

const FilterStyle = styled.div`
    display: flex;
    align-items: center;
    > label {
        font-size: 12px;
        width: 50px;
    }

    &[data-hascheckbox] > label {
        width: 100%;
    }

    > select {
        padding: 2px 4px;
    }

    > input[type="text"] {
        padding: 2px 4px;
    }
`;

export const BacklogFilters = connect(mapStateToProps, mapDispatchToProps)(Component);
