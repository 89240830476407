import { BacklogIssue } from "@pp/Components/Backlog/BacklogIssue";
import { getEpicColors } from "@pp/Components/Common/EpicTag";
import { ApplicationState } from "@pp/Store/ApplicationState";
import { getById } from "@pp/Store/Data/DataSelectors";
import { IssueModel } from "@pp/Store/Types";
import { connect } from "react-redux";
import * as React from "react";
import styled from "styled-components";

interface OwnProps {
    epicId: string;
    issues: IssueModel[];
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => ({
    epic:
        ownProps.epicId === "-1"
            ? {
                  name: "No Epic",
                  key: "",
                  url: "",
                  apiUrl: "",
                  color: "",
              }
            : getById(state.data.epics, ownProps.epicId).value,
});

type Props = ReturnType<typeof mapStateToProps> & OwnProps;

class Component extends React.Component<Props> {
    render() {
        if (!this.props.epic) {
            return null;
        }

        return (
            <>
                <EpicNameStyle {...getEpicColors(this.props.epic)}>
                    {this.props.epic.name}
                    <span>{this.props.issues.length}</span>
                </EpicNameStyle>
                <div>
                    {this.props.issues.map(issue => (
                        <BacklogIssue key={issue.id} issue={issue} />
                    ))}
                </div>
            </>
        );
    }
}

export const BacklogEpic = connect(mapStateToProps)(Component);

const EpicNameStyle = styled.div<{ backgroundColor: string; color: string }>`
    background-color: ${props => props.backgroundColor};
    color: ${props => props.color};
    font-size: 14px;
    padding: 10px 15px;
    display: flex;
    > span {
        margin-left: auto;
    }
`;
