import { Dictionary } from "@pp/Common/Types";
import { DataView, DataViewState, HasById } from "@pp/Store/Data/DataView";
import sortBy from "lodash/sortBy";

export function getDataViewKey(parameter: object | undefined) {
    if (!parameter) {
        return "EMPTY_KEY";
    }
    let dataViewKey = "";
    const keys = sortBy(Object.keys(parameter), o => o);
    for (const key of keys) {
        if (parameter.hasOwnProperty(key)) {
            const value = (parameter as Dictionary<string>)[key];
            const type = typeof value;
            if (type === "undefined" || type === "function") {
                continue;
            }

            const stringValue = type === "object" ? JSON.stringify(value) : value!.toString();
            dataViewKey += `${key}=${stringValue}&`;
        }
    }

    dataViewKey = dataViewKey.length > 0 ? dataViewKey.substr(0, dataViewKey.length - 1) : dataViewKey;

    return dataViewKey === "" ? "EMPTY_KEY" : dataViewKey;
}

export function getById<T>(dataViewState: HasById<T> | undefined, id: string | undefined, mapId?: (id: string) => string) {
    if (!id) {
        return idNotFound;
    }

    if (!dataViewState) {
        return dataViewNotFound;
    }

    return getOrStoreCachedResult(dataViewState, id, () => {
        const isLoading = dataViewState.isLoading[id];
        return {
            value: dataViewState.byId[!mapId ? id : mapId(id)],
            isLoading: !!isLoading,
        };
    });
}

function getOrStoreCachedResult<ModelType, ResultType extends object>(dataViewState: HasById<ModelType>, key: string, createResult: () => ResultType) {
    const cached = cacheMap.get(dataViewState)?.[key];
    if (cached) {
        return cached as Readonly<ResultType>;
    }

    const result = createResult();
    let dictionary = cacheMap.get(dataViewState);
    if (!dictionary) {
        dictionary = {};
        cacheMap.set(dataViewState, dictionary);
    }

    dictionary[key] = result;
    return result;
}

const cacheMap = new WeakMap<object, Dictionary<any>>();

export const dataViewNotFound = Object.freeze({
    isLoading: false,
    value: undefined,
} as const);

export function getDataView<T extends { id: string }, DataViewModel extends DataView>(
    dataViewState: DataViewState<T, DataViewModel> | undefined,
    parameter: object | undefined,
) {
    const key = getDataViewKey(parameter);
    if (!dataViewState) {
        return dataViewNotFound;
    }

    const dataView = dataViewState.dataViews[key];
    if (!dataView) {
        return dataViewNotFound;
    }

    return getOrStoreCachedResult(dataViewState, key, () => {
        let models: T[] | undefined;
        const { ids, ...otherProps } = dataView;
        if (ids) {
            models = [];
            for (const id of ids) {
                const model = dataViewState.byId[id];
                if (!model) {
                    throw new Error("There was no model found for the id of " + id);
                }
                models.push(model);
            }
        }

        return {
            value: models,
            ...otherProps,
        };
    });
}

const idNotFound = Object.freeze({
    isLoading: false,
    value: undefined,
    id: undefined,
} as const);
