import { request, RequestParameter } from "@pp/Atlassian/Request";
import { AppThunkAction } from "@pp/Store/AppThunkAction";
import { BoardModel } from "@pp/Store/Types";

export const loadBoards = (parameter: RequestParameter): AppThunkAction => {
    return dispatch => {
        dispatch({
            type: "Data/BeginLoadingCollection",
            dataType: "boards",
            parameter,
        });

        (async () => {
            let startAt = 0;
            let hasMore = true;

            const models: BoardModel[] = [];

            while (hasMore) {
                let url = parameter.url;
                url += url.indexOf("?") > -1 ? "&" : "?";
                url += `startAt=${startAt}`;

                const data: { values: BoardApiModel[]; isLast: boolean; maxResults: number } = await request({
                    ...parameter,
                    url,
                    type: "GET",
                });

                data.values.forEach(o => {
                    if (!o.location) {
                        return;
                    }
                    models.push({
                        id: o.id.toString(),
                        name: o.name,
                        self: o.self,
                        project: {
                            key: o.location.projectKey,
                        },
                    });
                });

                startAt = startAt + data.maxResults;
                hasMore = !data.isLast;
            }

            dispatch({
                type: "Data/CompleteLoadingCollection",
                dataType: "boards",
                models,
                parameter,
            });
        })();
    };
};
