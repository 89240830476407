import { request } from "@pp/Atlassian/Request";
import { AppThunkAction } from "@pp/Store/AppThunkAction";
import { BoardProperties, getBoardPropertyUrl } from "@pp/Store/Data/PropertySelectors";

export const updateProperty = (boardId: string, propertyName: BoardProperties, value: any): AppThunkAction => dispatch => {
    (async () => {
        let url = getBoardPropertyUrl(boardId, propertyName);

        await request({
            type: "PUT",
            url,
            data: value,
        });

        dispatch({
            type: "Data/CompleteLoadingModel",
            dataType: "properties",
            model: {
                id: url,
                value: value,
            },
        });
    })();
};
