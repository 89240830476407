import { colors } from "@pp/Components/Common/Colors";
import { IssueBlock } from "@pp/Components/Common/IssueBlock";
import { IssuePriority } from "@pp/Components/Common/IssuePriority";
import { IssueType } from "@pp/Components/Common/IssueType";
import { finishDraggingIssue } from "@pp/Store/Components/SprintPlanner/SprintPlannerActionCreators";
import { IssueModel } from "@pp/Store/Types";
import * as React from "react";
import { connect, ResolveThunks } from "react-redux";
import styled from "styled-components";

interface OwnProps {
    issue: IssueModel;
}

const mapDispatchToProps = {
    updateAssignee: finishDraggingIssue,
};

type Props = OwnProps & ResolveThunks<typeof mapDispatchToProps>;

class Component extends React.Component<Props> {
    dragEnd = () => {
        this.props.updateAssignee(this.props.issue.id);
    };

    render() {
        const { issue } = this.props;

        return (
            <IssueStyle key={issue.id} draggable={true} onDragEnd={this.dragEnd}>
                <IssueBlockStyle>
                    <IssueBlock issue={issue} />
                </IssueBlockStyle>
                <IssueKey href={issue.url} target="_blank">
                    {issue.key}
                </IssueKey>
                <IssueSummary>{issue.summary}</IssueSummary>
                <IssueTypeStyle typeId={issue.typeId} />
                <IssuePriorityStyle priorityId={issue.priorityId} />
                <IssuePoints>{issue.points ?? "-"}</IssuePoints>
            </IssueStyle>
        );
    }
}

export const BacklogIssue = connect(null, mapDispatchToProps)(Component);

const IssueStyle = styled.div`
    background-color: #fff;
    padding: 10px;
    display: flex;
    align-items: start;
    border-bottom: 1px solid ${colors.border};
    &:last-child {
        border-bottom: none;
    }
`;

const IssueKey = styled.a`
    white-space: nowrap;
`;

const IssueSummary = styled.span`
    width: 218px;
    padding: 0 8px;
    overflow-wrap: anywhere;
`;

const IssuePoints = styled.span`
    width: 28px;
    text-align: center;
    background-color: ${colors.pointsBackground};
    border-radius: 10px;
`;

const IssueTypeStyle = styled(IssueType)`
    width: 20px;
`;

const IssuePriorityStyle = styled(IssuePriority)`
    width: 20px;
    height: 20px;
`;

const IssueBlockStyle = styled.span`
    flex-basis: 20px;
    text-align: center;
`;
