import { getItem } from "@pp/Common/LocalStorage";
import { colors } from "@pp/Components/Common/Colors";
import Plus from "@pp/Components/Icons/Plus";
import { ApplicationState } from "@pp/Store/ApplicationState";
import { openAddTeamMember, setSelectedSprintId } from "@pp/Store/Components/SprintPlanner/SprintPlannerActionCreators";
import { getSelectedSprintId, getSprintIssuesDataView } from "@pp/Store/Components/SprintPlanner/SprintPlannerSelectors";
import { loadSprints } from "@pp/Store/Data/ActionCreators/LoadSprints";
import { getDataView } from "@pp/Store/Data/DataSelectors";
import { userFields } from "@pp/Store/Data/MapIssue";
import { getBoardAssigneeField } from "@pp/Store/Data/PropertySelectors";
import sumBy from "lodash/sumBy";
import * as React from "react";
import { connect, ResolveThunks } from "react-redux";
import styled from "styled-components";

const getSprintsParameter = (state: ApplicationState) => {
    if (!state.context.selectedBoardId) {
        return;
    }

    return {
        url: `/rest/agile/1.0/board/${state.context.selectedBoardId}/sprint?state=active,future`,
    };
};

const mapStateToProps = (state: ApplicationState) => {
    const selectedSprintId = getSelectedSprintId(state);
    const sprintsParameter = getSprintsParameter(state);
    const sprintIssuesDataView = getSprintIssuesDataView(state);
    const commitment = sprintIssuesDataView.value ? sumBy(sprintIssuesDataView.value, "points") : undefined;
    return {
        sprintsParameter,
        selectedSprintId,
        sprintsDataView: getDataView(state.data.sprints, sprintsParameter),
        boardAssigneeField: getBoardAssigneeField(state, state.context.selectedBoardId).value,
        commitment,
        selectedBoardId: state.context.selectedBoardId,
    };
};

const mapDispatchToProps = {
    loadSprints,
    setSelectedSprintId,
    openAddTeamMember,
};

type Props = ResolveThunks<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

class Component extends React.Component<Props> {
    componentDidMount() {
        if (!this.props.sprintsDataView.value && !this.props.sprintsDataView.isLoading && this.props.sprintsParameter) {
            this.props.loadSprints(this.props.sprintsParameter);
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (!prevProps.sprintsDataView.value && this.props.sprintsDataView.value) {
            const selectedSprintId = getItem("SelectedSprintId-" + this.props.selectedBoardId) ?? getItem("SelectedSprintId");
            if (selectedSprintId && selectedSprintId !== this.props.selectedSprintId && this.props.sprintsDataView.value.find(o => o.id === selectedSprintId)) {
                this.props.setSelectedSprintId(selectedSprintId);
            }
        }

        if (
            !this.props.sprintsDataView.value &&
            !this.props.sprintsDataView.isLoading &&
            this.props.sprintsParameter &&
            this.props.sprintsParameter.url !== prevProps.sprintsParameter?.url
        ) {
            this.props.loadSprints(this.props.sprintsParameter);
        }
    }

    onChange = (event: React.FormEvent<HTMLSelectElement>) => {
        this.props.setSelectedSprintId(event.currentTarget.value);
    };

    render() {
        if (!this.props.sprintsDataView.value || !this.props.boardAssigneeField) {
            return null;
        }

        const userField = userFields?.find(o => o.id === this.props.boardAssigneeField?.value);

        return (
            <HeaderStyle>
                <LeftSideStyle>
                    <select value={this.props.selectedSprintId} onChange={this.onChange} className="ak-field-select">
                        <option value="">Select Sprint</option>
                        {this.props.sprintsDataView.value.map(o => (
                            <option key={o.id} value={o.id}>
                                {o.name}
                            </option>
                        ))}
                    </select>
                </LeftSideStyle>
                <MiddleStyle>
                    <TeamMemberStyles>
                        <AssigneeStyle>{userField?.name}</AssigneeStyle>
                        <span>Team Members</span>
                        <AddTeamMember onClick={this.props.openAddTeamMember}>
                            <Plus size={18} />
                        </AddTeamMember>
                    </TeamMemberStyles>
                </MiddleStyle>
                <RightSideStyle>
                    <CommitmentStyle>{this.props.commitment}</CommitmentStyle>
                </RightSideStyle>
            </HeaderStyle>
        );
    }
}

export const SprintPlannerHeader = connect(mapStateToProps, mapDispatchToProps)(Component);

const HeaderStyle = styled.div`
    padding: 10px;
    display: flex;
    height: 60px;
    align-items: center;
`;

const LeftSideStyle = styled.div`
    width: 200px;
`;

const CommitmentStyle = styled.span`
    display: flex;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    padding: 5px;
    background-color: ${colors.pointsBackground};
    color: ${colors.pointsText};
`;

const MiddleStyle = styled.div`
    margin-left: 20px;
    display: flex;
    height: 24px;
    align-items: center;
    height: 100%;
`;

const RightSideStyle = styled.div`
    margin-left: 20px;
`;

const TeamMemberStyles = styled.div`
    font-size: 18px;
    display: flex;
`;

const AddTeamMember = styled.button`
    background: none;
    border: none;
    font-size: 20px;
    font-weight: bold;
    margin-left: 2px;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
`;

const AssigneeStyle = styled.span`
    width: 150px;
`;
