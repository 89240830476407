import { ApplicationState } from "@pp/Store/ApplicationState";
import { getById } from "@pp/Store/Data/DataSelectors";
import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

interface OwnProps {
    className?: string;
    statusId: string;
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => ({
    status: getById(state.data.statuses, ownProps.statusId).value,
});

type Props = OwnProps & ReturnType<typeof mapStateToProps>;

class Component extends React.Component<Props> {
    render() {
        if (!this.props.status) {
            return null;
        }

        return <SpanStyle className={this.props.className}>{this.props.status.name}</SpanStyle>;
    }
}

const SpanStyle = styled.span`
    display: flex;
    align-items: center;
`;

export const IssueStatus = connect(mapStateToProps)(Component);
