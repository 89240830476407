import { BacklogEpicsContainer } from "@pp/Components/Backlog/BacklogEpicsContainer";
import { BacklogFilters } from "@pp/Components/Backlog/BacklogFilters";
import * as React from "react";
import styled from "styled-components";

export class BacklogContainer extends React.Component {
    render() {
        return (
            <BacklogStyle>
                <HeaderStyle>Backlog</HeaderStyle>
                <BacklogFilters />
                <BacklogEpicsContainer />
            </BacklogStyle>
        );
    }
}

const BacklogStyle = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const HeaderStyle = styled.h2`
    text-align: center;
    padding: 10px;
`;
