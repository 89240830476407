import { createTypedReducerWithImmer } from "@pp/Common/CreateTypedReducer";
import { SprintPlannerState } from "@pp/Store/Components/SprintPlanner/SprintPlannerState";
import { Draft } from "immer";

const initialState: SprintPlannerState = {
    selectedSprintId: "",
    isAddTeamMemberOpen: false,
    teamMemberSearch: "",
};

const reducer = {
    "Components/SprintPlanner/SetSelectedSprintId": (draft: Draft<SprintPlannerState>, action: { id: string }) => {
        draft.selectedSprintId = action.id;
    },
    "Components/SprintPlanner/SetHoveringOverAssigneeId": (draft: Draft<SprintPlannerState>, action: { assigneeId: string | undefined }) => {
        draft.hoveringOverAssigneeId = action.assigneeId;
    },
    "Components/SprintPlanner/OpenAddTeamMember": (draft: Draft<SprintPlannerState>) => {
        draft.isAddTeamMemberOpen = true;
    },
    "Components/SprintPlanner/CloseAddTeamMember": (draft: Draft<SprintPlannerState>) => {
        draft.isAddTeamMemberOpen = false;
        draft.teamMemberSearch = "";
        draft.teamMemberUserData = undefined;
    },
    "Components/SprintPlanner/ClearTeamMemberSearch": (draft: Draft<SprintPlannerState>) => {
        draft.teamMemberSearch = "";
        draft.teamMemberUserData = undefined;
    },
    "Components/SprintPlanner/UpdateTeamMemberSearch": (draft: Draft<SprintPlannerState>, action: { value: string }) => {
        draft.teamMemberSearch = action.value;
    },
    "Components/SprintPlanner/UpdateTeamMemberUserData": (draft: Draft<SprintPlannerState>, action: { value: UserPickerApiModel }) => {
        draft.teamMemberUserData = action.value;
    },
};

export const sprintPlannerReducer = createTypedReducerWithImmer(initialState, reducer);
