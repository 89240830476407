import { ApplicationState } from "@pp/Store/ApplicationState";
import { getById } from "@pp/Store/Data/DataSelectors";
import { connect } from "react-redux";
import * as React from "react";

interface OwnProps {
    className?: string;
    priorityId: string;
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => ({
    priority: getById(state.data.priorities, ownProps.priorityId).value,
});

type Props = ReturnType<typeof mapStateToProps> & OwnProps;

class Component extends React.Component<Props> {
    render() {
        if (!this.props.priority) {
            return null;
        }

        return <img className={this.props.className} src={this.props.priority.iconUrl} title={this.props.priority.name} />;
    }
}

export const IssuePriority = connect(mapStateToProps)(Component);
