import { request, RequestParameter } from "@pp/Atlassian/Request";
import { AppThunkAction } from "@pp/Store/AppThunkAction";
import { loadFieldsIfNeeded, mapIssue, pointsField, sprintField, userFields } from "@pp/Store/Data/MapIssue";
import { AccountModel, EpicModel, PriorityModel, StatusModel, TypeModel } from "@pp/Store/Types";
import { Dictionary } from "@pp/Common/Types";

export const loadIssues = (parameter: RequestParameter, bypassBeginLoading?: boolean): AppThunkAction => dispatch => {
    (async () => {
        if (!bypassBeginLoading) {
            dispatch({
                type: "Data/BeginLoadingCollection",
                dataType: "issues",
                parameter,
            });
        }

        await loadFieldsIfNeeded();

        const data = {
            ...parameter.data,
            fields: `epic,assignee,summary,issuetype,priority,status,sprint,issuelinks,${pointsField},${sprintField},${userFields!.map(o => o.id).join(",")}`,
            maxResults: 1000,
        };

        const result: { issues: IssueApiModel[] } = await request({
            ...parameter,
            data,
            type: "GET",
        });

        const epics: Dictionary<EpicModel> = {};
        const accounts: Dictionary<AccountModel> = {};
        const priorities: Dictionary<PriorityModel> = {};
        const types: Dictionary<TypeModel> = {};
        const statuses: Dictionary<StatusModel> = {};
        const issues = result.issues.map(o => mapIssue(o, epics, accounts, priorities, types, statuses));

        dispatch({
            type: "Data/CompleteLoadingCollection",
            dataType: "epics",
            parameter,
            models: Object.keys(epics).map(o => epics[o]!),
        });

        dispatch({
            type: "Data/CompleteLoadingCollection",
            dataType: "accounts",
            models: Object.keys(accounts).map(o => accounts[o]!),
        });

        dispatch({
            type: "Data/CompleteLoadingCollection",
            dataType: "priorities",
            parameter,
            models: Object.keys(priorities).map(o => priorities[o]!),
        });

        dispatch({
            type: "Data/CompleteLoadingCollection",
            dataType: "types",
            models: Object.keys(types).map(o => types[o]!),
        });

        dispatch({
            type: "Data/CompleteLoadingCollection",
            dataType: "statuses",
            models: Object.keys(statuses).map(o => statuses[o]!),
        });

        dispatch({
            type: "Data/CompleteLoadingCollection",
            dataType: "issues",
            models: issues,
            parameter: parameter,
        });
    })();
};
