import { setItem } from "@pp/Common/LocalStorage";
import { AppThunkAction } from "@pp/Store/AppThunkAction";

export const setSelectedBoardId = (value: string): AppThunkAction => (dispatch, getState) => {
    (async () => {
        setItem("SelectedBoardId", value);

        dispatch({
            type: "Context/SetSelectedBoardId",
            value,
        });
    })();
};
