import { updateAssignee, updateSprint as updateSprintApi } from "@pp/Atlassian/ApiService";
import { request } from "@pp/Atlassian/Request";
import { timeout } from "@pp/Common/Helpers";
import { removeItem, setItem } from "@pp/Common/LocalStorage";
import { AppThunkAction } from "@pp/Store/AppThunkAction";
import { loadAccount } from "@pp/Store/Data/ActionCreators/LoadAccount";
import { updateProperty } from "@pp/Store/Data/ActionCreators/UpdateProperty";
import { getById } from "@pp/Store/Data/DataSelectors";
import { BoardProperties, getBoardAssigneeField, getBoardTeamMembersField } from "@pp/Store/Data/PropertySelectors";
import { AnyAction } from "@pp/Store/Reducers";
import cloneDeep from "lodash/cloneDeep";

export const openAddTeamMember = (): AnyAction => ({
    type: "Components/SprintPlanner/OpenAddTeamMember",
});

export const closeAddTeamMember = (): AnyAction => ({
    type: "Components/SprintPlanner/CloseAddTeamMember",
});

export const setSelectedSprintId = (sprintId: string): AppThunkAction => (dispatch, getState) => {
    const boardId = getState().context.selectedBoardId;

    setItem("SelectedSprintId-" + boardId, sprintId);
    removeItem("SelectedSprintId");

    dispatch({
        type: "Components/SprintPlanner/SetSelectedSprintId",
        id: sprintId,
    });
};

// TODO we need some better loading state and what not
export const updateTeamMemberSearch = (value: string): AppThunkAction => (dispatch, getState) => {
    dispatch({
        type: "Components/SprintPlanner/UpdateTeamMemberSearch",
        value,
    });

    (async () => {
        await timeout(150);

        if (getState().components.sprintPlanner.teamMemberSearch !== value) {
            return;
        }

        const data: UserPickerApiModel = await request({
            url: "/rest/api/3/user/picker?showAvatar=true&query=" + value,
        });

        if (getState().components.sprintPlanner.teamMemberSearch !== value) {
            return;
        }

        dispatch({
            type: "Components/SprintPlanner/UpdateTeamMemberUserData",
            value: data,
        });
    })();
};

export const addTeamMember = (accountId: string): AppThunkAction => (dispatch, getState) => {
    (async () => {
        if (!getById(getState().data.accounts, accountId).value) {
            dispatch(loadAccount(accountId));
        }

        // TODO simplify this board property shit! move it all to a single property
        // some way of updating stuff? migration? or just clean up the WHAM/WOAM boards?
        const teamMembers = getBoardTeamMembersField(getState(), getState().context.selectedBoardId).value?.value ?? [];
        if (!teamMembers.includes(accountId)) {
            dispatch(updateProperty(getState().context.selectedBoardId, BoardProperties.ppTeamMembersField, teamMembers.concat(accountId)));
        }

        dispatch({
            type: "Components/SprintPlanner/ClearTeamMemberSearch",
        });
    })();
};

export const updateSprint = (issueId: string, sprintId: string | null): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const issue = getById(state.data.issues, issueId).value;
    if (!issue) {
        throw new Error(`There was no issue found for the id ${issueId}`);
    }
    (async () => {
        await updateSprintApi(issueId, sprintId);

        if (issue.sprintId !== sprintId) {
            dispatch({
                type: "Data/SetIssueSprintId",
                issueId: issue.id,
                sprintId: sprintId ?? undefined,
            });
        }
    })();
};

export const finishDraggingIssue = (issueId: string): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const isHoveringOverBacklog = state.components.backlog.isHoveringOverBacklog;
    const sprintId = isHoveringOverBacklog ? null : state.components.sprintPlanner.selectedSprintId;
    const assigneeId = state.components.sprintPlanner.hoveringOverAssigneeId;

    const issue = getById(state.data.issues, issueId).value;
    if (!issue) {
        throw new Error(`There was no issue found for the id ${issueId}`);
    }

    (async () => {
        const assigneeField = getBoardAssigneeField(state, state.context.selectedBoardId).value!.value ?? "";

        const promises = [updateAssignee(issueId, assigneeId ? assigneeId : null, assigneeField)];
        if (issue.sprintId != sprintId) {
            promises.push(updateSprintApi(issueId, sprintId));
        }

        await Promise.all(promises);

        if (issue.sprintId !== sprintId) {
            dispatch({
                type: "Data/SetIssueSprintId",
                issueId: issue.id,
                sprintId: sprintId ?? undefined,
            });
        }

        const model = cloneDeep(issue);
        if (!assigneeId) {
            delete model.userFields[assigneeField];
        } else {
            model.userFields[assigneeField] = assigneeId;
        }

        dispatch({
            type: "Data/CompleteLoadingModel",
            dataType: "issues",
            model,
        });

        dispatch({
            type: "Components/SprintPlanner/SetHoveringOverAssigneeId",
            assigneeId: undefined,
        });
        dispatch({
            type: "Components/Backlog/SetHoveringOverBacklog",
            value: false,
        });
    })();
};

export const setHoveringOverAssigneeId = (assigneeId: string): AppThunkAction => dispatch => {
    dispatch({
        type: "Components/SprintPlanner/SetHoveringOverAssigneeId",
        assigneeId,
    });

    dispatch({
        type: "Components/Backlog/SetHoveringOverBacklog",
        value: false,
    });
};

export const setHoveringOverBacklog = (): AppThunkAction => dispatch => {
    dispatch({
        type: "Components/SprintPlanner/SetHoveringOverAssigneeId",
        assigneeId: undefined,
    });

    dispatch({
        type: "Components/Backlog/SetHoveringOverBacklog",
        value: true,
    });
};
