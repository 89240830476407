import { request } from "@pp/Atlassian/Request";
import { Dictionary } from "@pp/Common/Types";
import { mapAccount } from "@pp/Store/Data/MapAccount";
import { AccountModel, EpicModel, IssueModel, PriorityModel, StatusModel, TypeModel } from "@pp/Store/Types";

interface UserField {
    id: string;
    name: string;
}

export let pointsField: string | undefined;
export let sprintField: string | undefined;
export let userFields: UserField[] | undefined;

// TODO maybe this should just load when the app is setting itself up
export async function loadFieldsIfNeeded() {
    if (typeof pointsField === "undefined") {
        const fields: FieldApiModel[] = await request({
            url: "/rest/api/3/field",
        });

        userFields = [];

        for (const field of fields) {
            if (field.name === "Story Points") {
                pointsField = field.id;
            } else if (field.name === "Sprint") {
                sprintField = field.id;
            } else if (field.schema?.type === "user" && field.id !== "creator" && field.id !== "reporter") {
                userFields.push({
                    id: field.id,
                    name: field.name,
                });
            }
        }

        if (typeof pointsField === "undefined") {
            throw new Error("There was no Points field in the fields collection. " + JSON.stringify(fields));
        }
        if (typeof sprintField === "undefined") {
            throw new Error("There was no Sprint field in the fields collection. " + JSON.stringify(fields));
        }
    }

    return;
}

let host = "";
function getHost(self: string) {
    if (!host) {
        const url = new URL(self);
        host = url.protocol + "//" + url.host;
    }
    return host;
}

export function mapIssue(
    issueApiModel: IssueApiModel,
    epics: Dictionary<EpicModel>,
    accounts: Dictionary<AccountModel>,
    priorities: Dictionary<PriorityModel>,
    types: Dictionary<TypeModel>,
    statuses: Dictionary<StatusModel>,
) {
    const {
        epic: epicApiModel,
        summary,
        issuetype: typeApiModel,
        priority: priorityApiModel,
        status: statusApiModel,
        sprint: sprintApiModel,
        issuelinks,
    } = issueApiModel.fields;

    let epicId = undefined;

    if (epicApiModel) {
        epicId = epicApiModel.id.toString();
        const epic = epics[epicId];
        if (!epic) {
            epics[epicId] = {
                id: epicId,
                self: epicApiModel.self,
                color: epicApiModel.color.key,
                key: epicApiModel.key,
                name: epicApiModel.name,
                url: getHost(epicApiModel.self) + "/browse/" + epicApiModel.key,
            };
        }
    }

    const priorityId = priorityApiModel.id;
    const priority = priorities[priorityId];
    if (!priority) {
        priorities[priorityId] = {
            id: priorityId,
            iconUrl: priorityApiModel.iconUrl,
            name: priorityApiModel.name,
        };
    }

    const statusId = statusApiModel.id;
    const status = statuses[statusId];
    if (!status) {
        statuses[statusId] = {
            id: statusId,
            iconUrl: statusApiModel.iconUrl,
            name: statusApiModel.name,
        };
    }

    const typeId = typeApiModel.id;
    const type = types[typeId];
    if (!type) {
        types[typeId] = {
            id: typeId,
            iconUrl: typeApiModel.iconUrl,
            name: typeApiModel.name,
        };
    }

    const issue: IssueModel = {
        id: issueApiModel.id,
        summary: summary,
        key: issueApiModel.key,
        url: getHost(issueApiModel.self) + "/browse/" + issueApiModel.key,
        self: issueApiModel.self,
        epicId,
        blockedBy: [],
        blocks: [],
        points: (issueApiModel.fields as any)[pointsField!],
        priorityId: priorityId,
        sprintId: sprintApiModel?.id.toString(),
        statusId: statusId,
        typeId: typeId,
        userFields: {},
    };

    for (const userField of userFields!) {
        issue.userFields[userField.id] = (issueApiModel.fields as any)[userField.id]?.accountId;

        const apiAccount = (issueApiModel.fields as any)[userField.id];
        if (!apiAccount) {
            continue;
        }
        const accountId = apiAccount.accountId;
        const account = accounts[accountId];
        if (!account) {
            accounts[accountId] = mapAccount(apiAccount);
        }
        issue.userFields[userField.id] = accountId;
    }

    for (const issueLink of issuelinks) {
        if (issueLink.type.name === "Blocks") {
            if (issueLink.outwardIssue) {
                issue.blocks.push(issueLink.outwardIssue.id);
            } else if (issueLink.inwardIssue && issueLink.inwardIssue.fields.status.name !== "Done") {
                issue.blockedBy.push(issueLink.inwardIssue.id);
            }
        }
    }

    return issue;
}
