import * as React from "react";

const Plus: React.FC<{ size?: number; color1?: string; className?: string }> = ({ size = 24, color1 = "currentColor", className }) => (
    <svg
        className={className}
        focusable="false"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        fill="none"
        stroke={color1}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <path d="M12 5v14m-7-7h14" />
    </svg>
);

export default React.memo(Plus);
