import { createTypedReducerWithImmer } from "@pp/Common/CreateTypedReducer";
import { BacklogState } from "@pp/Store/Components/Backlog/BacklogState";
import { Draft } from "immer";

const initialState: BacklogState = {
    isHoveringOverBacklog: false,
    selectedEpicId: "",
    selectedPriorityId: "",
    selectedProjectKey: "",
    hideUnpointed: false,
    hidePointed: false,
    hideBlocked: false,
    search: "",
};

const reducer = {
    "Components/Backlog/SetHoveringOverBacklog": (draft: Draft<BacklogState>, action: { value: boolean }) => {
        draft.isHoveringOverBacklog = action.value;
    },
    "Components/Backlog/SetSelectedEpicId": (draft: Draft<BacklogState>, action: { value: string }) => {
        draft.selectedEpicId = action.value;
    },
    "Components/Backlog/SetSelectedPriorityId": (draft: Draft<BacklogState>, action: { value: string }) => {
        draft.selectedPriorityId = action.value;
    },
    "Components/Backlog/SetSelectedProjectKey": (draft: Draft<BacklogState>, action: { value: string }) => {
        draft.selectedProjectKey = action.value;
    },
    "Components/Backlog/SetHideUnpointed": (draft: Draft<BacklogState>, action: { value: boolean }) => {
        draft.hideUnpointed = action.value;
        if (draft.hideUnpointed) {
            draft.hidePointed = false;
        }
    },
    "Components/Backlog/SetHidePointed": (draft: Draft<BacklogState>, action: { value: boolean }) => {
        draft.hidePointed = action.value;
        if (draft.hidePointed) {
            draft.hideUnpointed = false;
        }
    },
    "Components/Backlog/SetHideBlocked": (draft: Draft<BacklogState>, action: { value: boolean }) => {
        draft.hideBlocked = action.value;
    },
    "Components/Backlog/SetSearch": (draft: Draft<BacklogState>, action: { value: string }) => {
        draft.search = action.value;
    },
};

export const backlogReducer = createTypedReducerWithImmer(initialState, reducer);
