import { request } from "@pp/Atlassian/Request";
import { sprintField } from "@pp/Store/Data/MapIssue";

export async function updateSprint(issueId: string, sprintId: string | null) {
    await request({
        url: `/rest/api/3/issue/${issueId}`,
        data: {
            fields: {
                [sprintField!]: sprintId === null ? sprintId : parseInt(sprintId, 10),
            },
        },
        type: "PUT",
    });
}

export async function updateAssignee(issueId: string, assigneeId: string | null, assigneeField: string) {
    if (assigneeField === "assignee") {
        await request({
            url: `/rest/api/3/issue/${issueId}/assignee`,
            data: {
                accountId: assigneeId,
            },
            type: "PUT",
        });
    } else {
        await request({
            url: `/rest/api/3/issue/${issueId}`,
            data: {
                fields: {
                    [assigneeField]: { accountId: assigneeId },
                },
            },
            type: "PUT",
        });
    }
}
