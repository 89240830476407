import { ApplicationState } from "@pp/Store/ApplicationState";
import { getById } from "@pp/Store/Data/DataSelectors";
import { connect } from "react-redux";
import * as React from "react";

interface OwnProps {
    className?: string;
    typeId: string;
    size?: number;
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => ({
    type: getById(state.data.types, ownProps.typeId).value,
});

type Props = ReturnType<typeof mapStateToProps> & OwnProps;

class Component extends React.Component<Props> {
    render() {
        if (!this.props.type) {
            return null;
        }

        return <img className={this.props.className} width={this.props.size ?? 16} src={this.props.type.iconUrl} title={this.props.type.name} />;
    }
}

export const IssueType = connect(mapStateToProps)(Component);
