import { request } from "@pp/Atlassian/Request";
import { AppThunkAction } from "@pp/Store/AppThunkAction";

export const loadProperty = (url: string): AppThunkAction => dispatch => {
    (async () => {
        dispatch({
            type: "Data/BeginLoadingModel",
            dataType: "properties",
            id: url,
        });

        const result: { key: string; value: any; errorMessages?: string[] } = await request({
            url,
        });

        const propertyModel = {
            id: url,
            value: undefined,
        };

        if (!result.errorMessages) {
            propertyModel.value = result.value;
        }

        dispatch({
            type: "Data/CompleteLoadingModel",
            dataType: "properties",
            model: propertyModel,
        });
    })();
};
